import { useMedia } from "react-use";

import DesktopHeader from "components/Header/DesktopHeader";
import MobileHeader from "components/Header/MobileHeader";

export default function Header() {
  const isDesktop = useMedia("(min-width: 1000px)");

  return isDesktop ? <DesktopHeader /> : <MobileHeader />;
}

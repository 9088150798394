/** @jsxImportSource @emotion/react */
import { ReactComponent as ParentCompaniesIcon } from "assets/icons/ParentCompaniesIcon.svg";
import { ReactComponent as UserIcon } from "assets/icons/User.svg";
import { menuItemCss } from "assets/css/linkCss";
import useLinks from "components/Header/useLinks";
import ROLES from "constants/user";
import ROUTE_PATHS from "constants/routePaths";
import useIsRoles from "hooks/useIsRoles";
import useIsProspectingRole from "hooks/useIsProspectingRole";
import useIsObserverRole from "hooks/useIsObserverRole";
import NavLink from "./NavLink";
import UserNavItem from "./UserNavItem";
import ParentCoNavItem from "./ParentCoNavItem";
import { headerNavbarCss } from "./index.css";

const SUPER_ADMIN_ROLES = [ROLES.SUPER_ADMIN];

export default function NavLinks() {
  const isSuperAdminRole = useIsRoles(SUPER_ADMIN_ROLES);
  const isProspectingRole = useIsProspectingRole();
  const isObserverRole = useIsObserverRole();

  const links = useLinks();

  const linksItems = links.map((route) => (
    <NavLink key={route.name} to={route.to} end={route.end} disabled={route.isDisabled}>
      {route.icon}
      {route.name}
    </NavLink>
  ));

  if (isProspectingRole) {
    linksItems.splice(linksItems.length, 0, [
      <NavLink to={ROUTE_PATHS.parentCompanies} css={menuItemCss} key="ParentCoNavItem">
        <ParentCompaniesIcon />

        <span>Parent Co.</span>
      </NavLink>,

      <NavLink to={ROUTE_PATHS.brands} css={menuItemCss} key="BrandsNavItem">
        <ParentCompaniesIcon />

        <span>Brands</span>
      </NavLink>,

      <NavLink to={ROUTE_PATHS.categories} css={menuItemCss} key="CategoriesNavItem">
        <ParentCompaniesIcon />

        <span>Categories</span>
      </NavLink>,
    ]);
  }

  if (isSuperAdminRole) {
    linksItems.splice(linksItems.length - 1, 0, <ParentCoNavItem key="ParentCoNavItem" />);

    linksItems.push(<UserNavItem key="UserNavItem" />);
  }

  if (isObserverRole) {
    linksItems.push(
      <NavLink to={ROUTE_PATHS.partners} css={menuItemCss} key="PartnersNavItem">
        <UserIcon />

        <span>Partners</span>
      </NavLink>,
    );
  }

  return (
    <div css={headerNavbarCss} className="main-text-grey-bold">
      {linksItems}
    </div>
  );
}

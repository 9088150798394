import fetchClient from "api/client";
import USER_DATA_ENDPOINT from "api/userData/userDataEndpoint";
import LOCAL_STORAGE_KEYS from "constants/localStorageKeys";
import localStorageHelper from "helpers/storage";

/**
 * @description GET api/userdata
 * @param {Object} context - https://tanstack.com/query/v4/docs/react/guides/query-functions#queryfunctioncontext
 * @param {["user", { token: string }]} context.queryKey
 *
 * @return {Promise<UserData|Error>}
 * */
export default function getUserData({ queryKey: [, { token }] }) {
  if (token) {
    localStorageHelper.set(LOCAL_STORAGE_KEYS.token, token);
  }

  return fetchClient.get(USER_DATA_ENDPOINT);
}

/**
 * @typedef {Object} UserData
 * @property {number} id
 * @property {string} email
 * @property {'EXTERNAL' | 'CLIENT_ADMIN' | 'SUPER_ADMIN' | 'OBSERVER' | 'OBSERVER_SELLER'} role
 * @property {string} given_name
 * @property {string | null} family_name
 * @property {Company} company
 * @property {string} company_name
 * @property {null} domain
 * @property {string} email_signature
 * @property {string} google_id
 * @property {null} picture
 * @property {null} reporting_emails
 * @property {null} smtp_email
 * @property {null} smtp_service
 * */

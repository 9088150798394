import { css } from "@emotion/react";
import colors from "assets/css/colors";

export const drawerWrapperCss = css`
  overflow: hidden;
  width: 200px;
  background-color: ${colors.mainBlack};
  position: relative;
  z-index: 2;
  transition: 0.5s all ease-in-out;
  div {
    justify-content: flex-start;
  }
`;

export const logoContainerCss = css`
  svg {
    margin: 16px;
  }
`;

/** @jsxImportSource @emotion/react */
import { ReactComponent as ArrowDownIcon } from "assets/icons/Arrow-Down.svg";
import UserAvatar from "components/common/UserAvatar";
import useGetUserDataQuery from "hookQueries/useGetUserDataQuery";
import joinWords from "helpers/joinWords";
import AccountInfoMenu from "../../AccountInfoMenu";
import getRole from "./getRole";
import {
  accountMenuClass,
  arrowCss,
  avatarWrapperCss,
  mainWrapperCss,
  roleCss,
  userInfoCss,
} from "./index.css";

export default function UserInfo() {
  const { data: user } = useGetUserDataQuery();
  const role = getRole(user);
  const userFullName = joinWords(user?.given_name, user?.family_name);

  return (
    <div css={mainWrapperCss} className="flex-a-c-j-c text">
      <div css={avatarWrapperCss}>
        <UserAvatar fullName={userFullName} picture={user.picture} />
      </div>

      <div css={userInfoCss} className="flex-a-st-j-c">
        <span>{userFullName}</span>

        <span css={roleCss}>{role}</span>
      </div>

      <div css={arrowCss} className="flex-a-c">
        <ArrowDownIcon />

        <div className={accountMenuClass}>
          <AccountInfoMenu isOpen />
        </div>
      </div>
    </div>
  );
}

export default {
  xxs: "440px",
  xs: "576px",
  sm: "768px",
  upSm: "769px",
  landingLg: "932px",
  lg: "992px",
  xl: "1280px",
  upXl: "1309px",
  xxl: "1400px",
};

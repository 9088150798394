export default {
  white: "#ffffff",
  textBlack: "#000000",
  mainBlack: "#121d33",
  landingBlack: "#120f2a",
  mainBlackOpacity08: "rgba(18, 29, 51, 0.08)",
  mainBlackOpacity25: "rgba(18, 29, 51, 0.25)",
  landingBlackOpacity85: "rgba(18, 15, 42, 0.85)",
  mainBlue: "#0085ff",
  landingBlue: "#2792f0",
  lightBlue: "#f2f9ff",
  tutorBlue: "#e4effc",
  darkBlue: "#0456c9",
  grey: "#b7b7b7",
  lightGrey: "#ebeced",
  textGrey: "#797979",
  landingLightGrey: "#eeeeee",
  landingTextGrey: "#949494",
  landingDarkGrey: "#636363",
  textGreyOpacity25: "rgba(121, 121, 121, 0.25)",
  bgGrey: "#f1f3f4",
  accentGray: "#ededed",
  strokeGrey: "#e5e5e5",
  green: "#0fbf2b",
  landingGreen: "#2d9f90",
  darkGreen: "#0ba023",
  lightred: "#ffcccb",
  red: "#df6439",
  darkRed: "#c92804",
  orange: "#eead07",
  darkOrange: "#c68e00",
  landingPink: "#fe4d6a",
  blackOverlay: "rgba(0, 0, 0, 0.65)",
};

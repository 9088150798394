import { css } from "@emotion/react";
import colors from "assets/css/colors";
import breakpoints from "assets/css/breakpoints";
import fontSize from "assets/css/fontSize";

export const dropDownContentCss = css`
  overflow: hidden;
  background-color: ${colors.mainBlack};
  min-width: 165px;

  @media screen and (max-width: ${breakpoints.lg}) {
    font-size: ${fontSize.sm};
    transition: all 0.2s linear;
    margin-left: 0;
    height: 0;
  }
`;

export const menuCss = css`
  width: 100%;

  @media screen and (max-width: ${breakpoints.lg}) {
    display: flex;
    flex-direction: column;
  }
`;

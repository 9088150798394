import { css } from "@emotion/react";
import colors from "assets/css/colors";

export const wrapperCss = css`
  height: 70px;
  background-color: ${colors.mainBlack};
`;

export const headerCss = css`
  height: 100%;
  color: ${colors.white};
`;

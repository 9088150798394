export default function joinWords(...args) {
  if (!args.length) return "";

  return args.reduce((acc, word) => {
    if (!word) return acc;
    if (!acc) return word;

    return `${acc} ${word}`;
  }, "");
}

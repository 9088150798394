import { css, keyframes } from "@emotion/react";
import colors from "assets/css/colors";

const barLoading = keyframes`
  0% {
    height: 75%;
  }
  50%,
  100% {
    height: 40%;
  }
`;

export const barsCss = css`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 80px;
`;

export const barCss = css`
  width: 16px;
  background: ${colors.orange};
  animation: ${barLoading} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  &:nth-of-type(1) {
    animation-delay: -0.24s;
  }
  &:nth-of-type(2) {
    animation-delay: -0.12s;
  }
`;

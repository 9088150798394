/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useMeasure } from "react-use";
import { dropDownContentCss, menuCss } from "./index.css";

export default function MenuDropdown({ isOpen, children }) {
  const [containerRef, { height: menuHeight }] = useMeasure();
  const height = isOpen ? menuHeight : 0;

  return (
    <div css={dropDownContentCss} style={{ height }}>
      <div ref={containerRef} css={menuCss}>
        {children}
      </div>
    </div>
  );
}

MenuDropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

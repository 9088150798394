import { css } from "@emotion/react";
import { linkCss } from "assets/css/linkCss";
import { columnGap4Css } from "assets/css/gaps.css";

export const headerNavbarCss = css`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1000px) {
    flex: 1 1 0;
    max-width: 650px;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
  }
`;

export const usersItemCss = [
  columnGap4Css,
  css`
    display: inline-flex;
    align-items: center;
  `,
];

export const getItemContainerCss = (itemMenuClass) => [
  linkCss,
  css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .${itemMenuClass} {
      position: absolute;
      transition: 0.1s all linear;
      right: 0;
      left: 0;
      opacity: 0;
      z-index: -1;
      top: 100%;
    }

    &:hover {
      .${itemMenuClass} {
        opacity: 1;
        z-index: 10000;
      }
    }
  `,
];

/** @jsxImportSource @emotion/react */
import { ReactComponent as UserManagementIcon } from "assets/icons/UserManagementIcon.svg";
import useIsUsersPath from "hooks/useIsUsersPath";
import UsersLinkMenu from "./UsersLinkMenu";
import { getItemContainerCss, usersItemCss } from "../index.css";

export const usersMenuClass = "users-menu";

export default function UserNavItem() {
  const isUsersPath = useIsUsersPath();

  return (
    <div css={getItemContainerCss(usersMenuClass)} className={isUsersPath ? "active" : undefined}>
      <div css={usersItemCss} className="flex-a-c">
        <UserManagementIcon />
        Users
      </div>

      <div className={usersMenuClass}>
        <UsersLinkMenu isOpen />
      </div>
    </div>
  );
}

/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import useAuth from "context/authContext/useAuth";
import AUTH_STATUS from "constants/auth";
import ROUTE_PATHS from "constants/routePaths";
import UserAvatar from "components/common/UserAvatar";
import UserInfo from "./UserInfo";
import { loginBtnCss } from "./index.css";

export default function User() {
  const authStatus = useAuth();

  if (authStatus === AUTH_STATUS.pending) return <UserAvatar fullName="" />; // as Loader
  if (authStatus === AUTH_STATUS.authorized) return <UserInfo />;

  return (
    <Link to={ROUTE_PATHS.login} css={loginBtnCss}>
      Log In
    </Link>
  );
}

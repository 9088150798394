/**
 * @return {["user"]}
 * */
export const userKey = () => ["user"];

/**
 * @param {T} token
 *
 * @return {["user", { token: T }]}
 * */
export const userTokenKey = (token) => [...userKey(), { token }];

/** @jsxImportSource @emotion/react */
import LogoLink from "../LogoLink";
import NavLinks from "../NavLinks";
import User from "../User";
import { headerCss, wrapperCss } from "../index.css";

export default function DesktopHeader() {
  return (
    <header css={wrapperCss}>
      <div css={headerCss} className="container flex-a-c-j-sb">
        <LogoLink />

        <NavLinks />

        <User />
      </div>
    </header>
  );
}

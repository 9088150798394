/** @jsxImportSource @emotion/react */
import { ReactComponent as ParentCompaniesIcon } from "assets/icons/ParentCompaniesIcon.svg";
import useIsParentCoPath from "hooks/useIsParentCoPath";
import ParentCoLinkMenu from "./ParentCoLinkMenu";
import { getItemContainerCss, usersItemCss } from "../index.css";

export const parentCoMenuClass = "parentCo-menu";

export default function ParentCoNavItem() {
  const isParentCoPath = useIsParentCoPath();

  return (
    <div
      css={getItemContainerCss(parentCoMenuClass)}
      className={isParentCoPath ? "active" : undefined}
    >
      <div css={usersItemCss} className="flex-a-c">
        <ParentCompaniesIcon />
        Parent Co.
      </div>

      <div className={parentCoMenuClass}>
        <ParentCoLinkMenu isOpen />
      </div>
    </div>
  );
}

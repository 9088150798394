import { useLocation, useSearchParams } from "react-router-dom";
import useEvent from "hooks/useEvent";

/**
 * @description wrap useSearchParams and add state from previous location
 * */
export default function useRouterSearchParams() {
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const handleSetSearchParams = useEvent((updater, options) =>
    setSearchParams(updater, { state, ...options }),
  );

  return [searchParams, handleSetSearchParams];
}

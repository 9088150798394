import idKeyFactory from "helpers/idKeyFactory";

/**
 * @return  ['prospect']
 * */
export const prospectKey = () => ["prospect"];

/**
 * @param {string | number} [ID]
 *
 * @return {['prospect', { id: string }]}
 * */
export const prospectIdKey = idKeyFactory(prospectKey);

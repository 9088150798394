/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useToggle } from "react-use";
import { ReactComponent as ArrowDownIcon } from "assets/icons/Arrow-Down.svg";
import AccountInfoMenu from "../../AccountInfoMenu";
import { mobDrawerBtnCss, mobUserInfoWrapperCss, userMailCss } from "./index.css";

export default function LoggedSidebarContent({ email }) {
  const [isOpen, toggleIsOpen] = useToggle(false);

  return (
    <div css={mobUserInfoWrapperCss}>
      <button
        type="button"
        css={mobDrawerBtnCss({ isOpen })}
        className="flex-a-c-j-c"
        onClick={toggleIsOpen}
      >
        <span css={userMailCss}>{email}</span>

        <ArrowDownIcon />
      </button>

      <AccountInfoMenu isOpen={isOpen} />
    </div>
  );
}

LoggedSidebarContent.propTypes = {
  email: PropTypes.string,
};

const ID_TYPES = ["string", "number"];
const expectedTypes = ID_TYPES.join(", ");

export default function idKeyFactory(createKeys) {
  return (ID) => {
    const valueType = typeof ID;
    const isValidType = ID_TYPES.includes(valueType);
    const id = isValidType ? ID.toString() : "";

    if (!isValidType && process.env.NODE_ENV === "development") {
      console.error(`$Wrong ID type of "${valueType}". Expected types: ${expectedTypes}`);
    }

    return [...createKeys(), { id }];
  };
}

/** @jsxImportSource @emotion/react */
import { memo } from "react";
import range from "helpers/range";
import { barCss, barsCss } from "./index.css";

const BARS = range(3);

function Loading() {
  return (
    <div className="flex-a-c-j-c">
      <div css={barsCss}>
        {BARS.map((i) => (
          <div key={i} css={barCss} />
        ))}
      </div>
    </div>
  );
}

export default memo(Loading);

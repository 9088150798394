/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { initialsCss, shapeCss } from "./index.css";

export default function UserAvatar({ fullName, picture }) {
  const [isValid, setIsValid] = useState(() => !!picture);
  const onError = () => setIsValid(false);

  useEffect(() => setIsValid(!!picture), [picture]);

  if (isValid) return <img css={shapeCss} src={picture} alt={fullName} onError={onError} />;

  const initials = fullName.split(" ").reduce((acc, word) => {
    const char = word?.[0];

    if (char) return `${acc}${char}`;

    return acc;
  }, "");

  return (
    <div css={initialsCss} className="flex-a-c-j-c">
      {initials}
    </div>
  );
}

UserAvatar.propTypes = {
  fullName: PropTypes.string.isRequired,
  picture: PropTypes.string,
};

import { useMemo } from "react";
import { useLocation, matchPath } from "react-router-dom";

export default function useIsUsersPath() {
  const { pathname } = useLocation();

  return useMemo(
    () => ["admin/users", "admin/partners"].some((pattern) => matchPath(pattern, pathname)),
    [pathname],
  );
}

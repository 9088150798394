import { useEffect } from "react";

const PROPERTY = "--scroll-y";

function scrollListener() {
  document.documentElement.style.setProperty(PROPERTY, `${window.scrollY}px`);
}

function cleanup() {
  const { body } = document;
  body.style.position = "";
  body.style.top = "";
  body.style.width = "";
}

export default function usePreventScroll(locked) {
  useEffect(() => {
    const { body } = document;
    if (locked) {
      const scrollY = document.documentElement.style.getPropertyValue(PROPERTY);
      body.style.position = "fixed";
      body.style.top = `-${scrollY}`;
      body.style.width = "100%";

      return cleanup;
    }

    const scrollY = body.style.top;
    cleanup();

    window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);

    return cleanup;
  }, [locked]);

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);
}

import { useMemo } from "react";
import { useLocation, matchPath } from "react-router-dom";

export default function useIsParentCoPath() {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      ["admin/parent-companies", "admin/brands"].some((pattern) => matchPath(pattern, pathname)),
    [pathname],
  );
}

import { css } from "@emotion/react";
import colors from "assets/css/colors";

export const mainWrapperCss = css`
  height: 100%;
  transition: all 0.5s ease-in;
  color: ${colors.white};
  border: none;
  padding: 0;
  background-color: ${colors.mainBlack};
  position: relative;
`;

export const avatarWrapperCss = css`
  margin-left: 12px;
  margin-right: 8px;
`;

export const userInfoCss = css`
  flex-direction: column;
  height: 40px;
  box-sizing: border-box;
  text-align: start;
`;

export const roleCss = css`
  color: ${colors.grey};
`;

export const accountMenuClass = "account-menu";

export const arrowCss = css`
  height: 100%;
  padding-left: 20px;
  cursor: pointer;

  svg {
    path {
      stroke: ${colors.grey};
    }
  }

  .${accountMenuClass} {
    position: absolute;
    transition: 0.1s all linear;
    right: 0;
    opacity: 0;
    z-index: -1;
    top: 100%;
  }

  &:hover {
    .${accountMenuClass} {
      opacity: 1;
      z-index: 9999;
    }
  }
`;

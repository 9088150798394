/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { dividerCss } from "assets/css/divider.css";
import { menuItemCss } from "assets/css/linkCss";
import { ReactComponent as UserIcon } from "assets/icons/User.svg";
import ROUTE_PATHS from "constants/routePaths";
import MenuDropdown from "../../MenuDropdown";

export default function UsersLinkMenu({ isOpen }) {
  return (
    <MenuDropdown isOpen={isOpen}>
      <NavLink to={ROUTE_PATHS.users} css={menuItemCss}>
        <UserIcon />

        <span>Users</span>
      </NavLink>

      <div css={dividerCss} />

      <NavLink to={ROUTE_PATHS.partners} css={menuItemCss}>
        <UserIcon />

        <span>Partners</span>
      </NavLink>

      <NavLink to={ROUTE_PATHS.teams} css={menuItemCss}>
        <UserIcon />

        <span>Team</span>
      </NavLink>
    </MenuDropdown>
  );
}

UsersLinkMenu.propTypes = {
  isOpen: PropTypes.bool,
};

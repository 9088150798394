import UAParser from "ua-parser-js";
import LOCAL_STORAGE_KEYS from "constants/localStorageKeys";
import packageJson from "../../package.json";

const MARK = "```";
const START_MARK = `${MARK}\n`;
const END_MARK = `\n${MARK}`;
const parser = new UAParser();

/**
 * @returns {string}
 * */
export default function getBugReportData() {
  const { os, browser } = parser.getResult();

  return `${START_MARK}${JSON.stringify(
    {
      os,
      browser,
      location: window.location,
      token: window.localStorage.getItem(LOCAL_STORAGE_KEYS.token),
      appVersion: packageJson.version,
      timestamp: new Date(),
    },
    null,
    2,
  )}${END_MARK}`;
}

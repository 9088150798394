import { useMemo } from "react";
import useGetUserDataQuery from "hookQueries/useGetUserDataQuery";
import selectRoleField from "hookQueries/useGetUserDataQuery/selectRoleField";

const GET_USER_DATA_QUERY_OPTIONS = {
  select: selectRoleField,
};

export default function useIsRoles(roles) {
  const { data: role } = useGetUserDataQuery(GET_USER_DATA_QUERY_OPTIONS);

  return useMemo(() => roles.includes(role), [role, roles]);
}

import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import LoadingSuspense from "components/LoadingSuspense";
import AuthProvider from "context/authContext/AuthProvider";

const ToastContainer = lazy(() => import("components/ToastContainer"));

export default function RootElement() {
  return (
    <AuthProvider>
      <LoadingSuspense>
        <Outlet />
      </LoadingSuspense>

      <Suspense fallback={null}>
        <ToastContainer />
      </Suspense>
    </AuthProvider>
  );
}

import { css } from "@emotion/react";
import colors from "assets/css/colors";
import { columnGap4Css } from "assets/css/gaps.css";

const linkBreakpoint = "@media screen and (min-width: 1000px)";

const baseLinkCss = [
  columnGap4Css,
  css`
    display: inline-flex;
    align-items: center;
    height: 42px;
    border-radius: 5px;
    margin: 5px 19px;
    border: 1px solid transparent;
    padding: 5px;
    text-decoration: none;
    text-transform: capitalize;
    color: ${colors.white};
    box-sizing: border-box;
    transition: 0.5s all;

    &[disabled] {
      color: ${colors.grey};
      cursor: not-allowed;
    }

    &.active {
      color: ${colors.mainBlue};
      border-color: currentColor;
    }

    ${linkBreakpoint} {
      height: 100%;
      border: none;
      padding: unset;
      margin: unset;
    }
  `,
];

export const linkCss = [
  baseLinkCss,
  css`
    ${linkBreakpoint} {
      &:not([disabled]):hover {
        color: ${colors.mainBlue};
      }

      &.active {
        border: none;
        border-radius: unset;
        position: relative;
        box-sizing: border-box;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 3px;
          background-color: ${colors.mainBlue};
        }
      }
    }
  `,
];

const menuItemBaseCss = [
  baseLinkCss,
  css`
    svg path {
      stroke: currentColor !important;
    }

    ${linkBreakpoint} {
      width: 100%;
      padding: 12px 8px 12px 12px;

      &.active,
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  `,
];

export const menuItemCss = [
  menuItemBaseCss,
  css`
    ${linkBreakpoint} {
      &.active,
      &:hover {
        color: ${colors.mainBlue};
      }
    }
  `,
];

export const menuItemRedCss = [
  menuItemBaseCss,
  css`
    color: ${colors.red};
  `,
];

/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { menuItemCss } from "assets/css/linkCss";
import { ReactComponent as LogInIcon } from "assets/icons/LogIn.svg";
import LOCAL_STORAGE_KEYS from "constants/localStorageKeys";
import ROUTE_PATHS from "constants/routePaths";
import useLocalStorage from "context/localStorageContext/useLocalStorage";

export default function LogAsAdminButton({ adminToken }) {
  const { removeItem, setItem } = useLocalStorage();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const onLoginAsClick = () => {
    queryClient.clear();

    setItem(LOCAL_STORAGE_KEYS.token, adminToken);

    removeItem(LOCAL_STORAGE_KEYS.adminToken);

    navigate(ROUTE_PATHS.admin);
  };

  return (
    <button type="button" css={menuItemCss} onClick={onLoginAsClick}>
      <LogInIcon />

      <span>as Admin</span>
    </button>
  );
}

LogAsAdminButton.propTypes = {
  adminToken: PropTypes.string,
};

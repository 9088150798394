import { css } from "@emotion/react";
import colors from "assets/css/colors";

export const headerWrapperCss = css`
  position: relative;
  height: 70px;
  width: calc(100% - 10%);
  background-color: ${colors.mainBlack};
  padding: 0 5% 0 5%;
  color: ${colors.white};
`;

export const drawerBtnCss = css`
  border: none;
  background-color: ${colors.mainBlack};
  color: ${colors.white};
  padding: 0;
  cursor: pointer;
  z-index: 10;
  svg {
    border: 1px solid ${colors.white};
    padding: 5px;
    border-radius: 3px;
    fill: ${colors.white};
    path {
      stroke: ${colors.white};
    }
  }
`;

export const drawerCss = css`
  &.rc-drawer,
  .rc-drawer-mask {
    right: 0;
  }

  &.rc-drawer {
    position: fixed;
    pointer-events: none;
  }

  .rc-drawer-mask {
    position: absolute;
    background-color: ${colors.mainBlack};
    opacity: 0.6;
    pointer-events: auto;
  }

  .rc-drawer-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    pointer-events: auto;
  }

  .rc-drawer-content-wrapper {
    position: absolute;
    overflow: hidden;
    transition: transform 0.3s;
    background-color: ${colors.mainBlack};
  }

  .rc-drawer-content-wrapper-hidden {
    display: none;
  }

  &.rc-drawer,
  .rc-drawer-mask,
  &.rc-drawer-left .rc-drawer-content-wrapper {
    top: 0;
    bottom: 0;
    left: 0;
  }

  &.rc-drawer,
  .rc-drawer-mask,
  .rc-drawer-content-wrapper {
    z-index: 1050;
  }
`;

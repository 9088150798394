import PropTypes from "prop-types";
import ROLES from "constants/user";
import useGetUserDataQuery from "hookQueries/useGetUserDataQuery";
import selectRoleField from "hookQueries/useGetUserDataQuery/selectRoleField";

const GET_USER_DATA_QUERY_OPTIONS = {
  select: selectRoleField,
};

export default function ShowContentForRoles({ roles, children }) {
  const { data: role } = useGetUserDataQuery(GET_USER_DATA_QUERY_OPTIONS);

  return roles.includes(role) && children;
}

ShowContentForRoles.propTypes = {
  roles: PropTypes.arrayOf(
    PropTypes.oneOf([
      ROLES.EXTERNAL,
      ROLES.CLIENT_ADMIN,
      ROLES.SUPER_ADMIN,
      ROLES.OBSERVER,
      ROLES.OBSERVER_SELLER,
      ROLES.PROSPECTING,
    ]),
  ).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

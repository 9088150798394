import { css } from "@emotion/react";
import colors from "assets/css/colors";

const mobDrawerCloseCss = css`
  border: none;
  background-color: ${colors.mainBlack};
  color: ${colors.white};
  //padding: 10px;
  height: 50px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  svg {
    margin-right: 10px;
    height: 18px;
    path {
      stroke: ${colors.white};
    }
  }
  :hover {
    color: ${colors.mainBlue};
  }
  &.isOpen {
    & svg {
      transform: rotate(180deg);
    }
  }
`;

const mobDrawerOpenCss = [
  mobDrawerCloseCss,
  css`
    & svg {
      transform: rotate(180deg);
    }
  `,
];

export const mobUserInfoWrapperCss = css`
  border-top: 1px solid ${colors.textGreyOpacity25};
  border-bottom: 1px solid ${colors.textGreyOpacity25};
  svg {
    margin-right: 0;
  }
`;

export const userMailCss = css`
  width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  margin-left: 19px;
`;

export function mobDrawerBtnCss({ isOpen }) {
  return isOpen ? mobDrawerOpenCss : mobDrawerCloseCss;
}

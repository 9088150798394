import { css } from "@emotion/react";
import colors from "assets/css/colors";
import fontSize from "assets/css/fontSize";
import fontWeight from "assets/css/fontWeight";
import lineHeight from "assets/css/lineHeight";

export default css`
  .text,
  .medium-text,
  .main-text,
  .main-text-grey,
  .main-text-grey-bold {
    font-size: ${fontSize.sm};
    font-weight: ${fontWeight.normal};
    line-height: ${lineHeight.xs};
  }

  .text-small {
    font-size: ${fontSize.xs};
    line-height: ${lineHeight.xxs};
    font-weight: ${fontWeight.normal};
    color: ${colors.textGrey};
  }

  .text-bold {
    font-size: ${fontSize.sm};
    font-weight: ${fontWeight.medium};
    line-height: ${lineHeight.xs};
  }

  .medium-text {
    font-weight: ${fontWeight.medium};
  }

  .main-text {
    color: ${colors.mainBlack};
  }

  .main-text-grey,
  .main-text-grey-bold {
    color: ${colors.textGrey};
  }

  .main-text-grey-bold {
    font-weight: ${fontWeight.medium};
  }

  .main-title {
    font-size: ${fontSize.xl};
    font-weight: ${fontWeight.medium};
    color: ${colors.mainBlack};
    line-height: ${lineHeight.lg};
  }

  .medium-title {
    font-size: ${fontSize.lg};
    font-weight: ${fontWeight.medium};
    color: ${colors.mainBlack};
    line-height: ${lineHeight.md};
  }
`;

import { lazy } from "react";
import { Outlet } from "react-router-dom";
import Footer from "components/Footer";
import Header from "components/Header";
import LoadingSuspense from "components/LoadingSuspense";
import ROUTE_PATHS from "constants/routePaths";

const NotFound = lazy(() => import("pages/notFound"));
const PolicyPage = lazy(() => import("pages/agreements/Policy"));
const TermsPage = lazy(() => import("pages/agreements/Terms"));
const Report = lazy(() => import("pages/report"));
const ApproveScreen = lazy(() => import("pages/admin/approveScreen"));

const PUBLIC_ROUTE_OBJECT = {
  element: (
    <>
      <Header />

      <main>
        <LoadingSuspense>
          <Outlet />
        </LoadingSuspense>
      </main>

      <Footer />
    </>
  ),
  children: [
    { path: ROUTE_PATHS.terms, element: <TermsPage /> },
    { path: ROUTE_PATHS.policy, element: <PolicyPage /> },
    { path: ROUTE_PATHS.approvedScreen, element: <ApproveScreen /> },
    { path: ROUTE_PATHS.report, element: <Report /> },
    { path: "*", element: <NotFound /> },
  ],
};

export default PUBLIC_ROUTE_OBJECT;

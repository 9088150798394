import ROLES from "constants/user";

const ADMIN_TITLE = "Admin";
const OBSERVER_TITLE = "Observer";

const TITLE = {
  [ROLES.PROSPECTING]: "Prospecting",
  [ROLES.EXTERNAL]: "External User",
  [ROLES.SUPER_ADMIN]: ADMIN_TITLE,
  [ROLES.CLIENT_ADMIN]: ADMIN_TITLE,
  [ROLES.OBSERVER]: OBSERVER_TITLE,
  [ROLES.OBSERVER_SELLER]: OBSERVER_TITLE,
};

/**
 * @param {Object} user
 * @param {string} [user.company_name]
 * @param {"EXTERNAL" | "CLIENT_ADMIN" | "SUPER_ADMIN" | "OBSERVER" | "OBSERVER_SELLER"} [user.role]
 *
 * @return {string}
 * */
export default function getRole(user) {
  if (user.company_name) return user.company_name;

  return TITLE[user.role] ?? "Unknown";
}

import { css } from "@emotion/react";
import colors from "assets/css/colors";

export const shapeCss = css`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;

export const initialsCss = [
  shapeCss,
  css`
    text-transform: uppercase;
    background-color: ${colors.mainBlue};
  `,
];

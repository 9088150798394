import { css } from "@emotion/react";
import colors from "assets/css/colors";

export const wrapperCss = css`
  box-sizing: border-box;
  width: 100%;
  min-height: 64px;
  margin-top: auto;
  padding: 0 80px;
  color: ${colors.white};
  background-color: ${colors.mainBlack};
`;

export const linkCss = css`
  text-decoration: none;
  color: ${colors.white};

  &.active,
  &:hover {
    color: ${colors.mainBlue};
  }
`;

import { css } from "@emotion/react";
import colors from "assets/css/colors";
import fontFamily from "assets/css/fontFamily";
import fontSize from "assets/css/fontSize";
import fontWeight from "assets/css/fontWeight";
import lineHeight from "assets/css/lineHeight";

export default css`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  max-height: 44px;
  box-sizing: border-box;
  border: none;
  padding: 12px 17.5px;

  font-family: ${fontFamily.roboto};
  font-size: ${fontSize.sm};
  font-weight: ${fontWeight.medium};
  line-height: ${lineHeight.xs};
  text-decoration: none;

  border-radius: 4px;
  color: ${colors.white};
  transition: 0.3s all ease-in-out;

  &:disabled {
    opacity: 0.25;
  }
`;

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Loading from "components/common/Loading";
import useGetUserDataQuery from "hookQueries/useGetUserDataQuery";
import selectRoleField from "hookQueries/useGetUserDataQuery/selectRoleField";

export default function RouteByRole({ children, roles, to }) {
  const {
    isLoading,
    error,
    data: role,
  } = useGetUserDataQuery({
    select: selectRoleField,
  });

  if (isLoading) {
    return (
      <div className="container">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <div className="container">
        <h3>Error: ${error.message}</h3>
      </div>
    );
  }

  if (!role) {
    return (
      <div className="container">
        return <h3>Unauthorized</h3>
      </div>
    );
  }

  if (!roles.includes(role)) {
    return (
      <div className="container">
        <h3>Forbidden for your role</h3>

        {!!to && (
          <div>
            <Link to={to} replace>
              safe place
            </Link>
          </div>
        )}
      </div>
    );
  }

  return children;
}

RouteByRole.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      search: PropTypes.string,
      hash: PropTypes.string,
    }),
  ]),
};

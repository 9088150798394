import { lazy } from "react";
import ROUTE_PATHS from "constants/routePaths";

const AuthElement = lazy(() => import("routing/AuthElement"));
const WelcomePage = lazy(() => import("pages/welcome"));
const ResetPasswordPage = lazy(() => import("pages/resetPassword"));
const ReminderPage = lazy(() => import("pages/reminder"));
const LoginComponent = lazy(() => import("components/Login"));
const TeamLoginComponent = lazy(() => import("components/TeamLogin"));

const AUTH_ROUTE_OBJECT = {
  path: ROUTE_PATHS.auth,
  element: <AuthElement />,
  children: [
    { path: ROUTE_PATHS.login, element: <LoginComponent /> },
    { path: ROUTE_PATHS.loginTeam, element: <TeamLoginComponent /> },
    { path: ROUTE_PATHS.resetPassword, element: <ResetPasswordPage /> },
    { path: ROUTE_PATHS.reminder, element: <ReminderPage /> },
    { path: ROUTE_PATHS.welcome, element: <WelcomePage /> },
  ],
};

export default AUTH_ROUTE_OBJECT;

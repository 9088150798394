import { css } from "@emotion/react";
import boxShadow from "assets/css/boxShadow";
import colors from "assets/css/colors";
import buttonCss from "assets/css/buttons/button.css";

export default [
  buttonCss,
  css`
    background-color: ${colors.mainBlue};
    &:active {
      background-color: ${colors.darkBlue};
    }
    &:hover,
    &:active {
      box-shadow: ${boxShadow.blue};
    }
  `,
];

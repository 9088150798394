import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import ROUTE_PATHS from "constants/routePaths";
import ADMIN_ROUTE_OBJECT from "routing/adminRouteObject";
import AUTH_ROUTE_OBJECT from "routing/authRouteObject";
import PUBLIC_ROUTE_OBJECT from "routing/publicRouteObject";
import RootElement from "routing/RootElement";

const LandingPage = lazy(() => import("pages/landing"));

export default createBrowserRouter([
  {
    element: <RootElement />,
    children: [
      { path: ROUTE_PATHS.root, element: <LandingPage /> },
      AUTH_ROUTE_OBJECT,
      ADMIN_ROUTE_OBJECT,
      PUBLIC_ROUTE_OBJECT,
    ],
  },
]);

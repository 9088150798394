/** @jsxImportSource @emotion/react */
import { menuItemCss } from "assets/css/linkCss";
import { ReactComponent as IconDownload } from "assets/icons/Download.svg";
import getBugReportData from "helpers/getBugReportData";
import { toast } from "react-toastify";

const copyToClipboard = async () => {
  window.navigator.clipboard
    .writeText(getBugReportData())
    .then(() => {
      toast.success("Bug report copied to clipboard");
    })
    .catch((error) => {
      console.error(error);
      toast.error("Bug report didn't copy");
    });
};

export default function BugReport() {
  return (
    <button
      title="copy bug report to clipboard"
      type="button"
      css={menuItemCss}
      onClick={copyToClipboard}
    >
      <IconDownload />

      <span>bug report</span>
    </button>
  );
}

import { Suspense } from "react";
import PropTypes from "prop-types";
import Loading from "components/common/Loading";

export default function LoadingSuspense({ children }) {
  return <Suspense fallback={<Loading />}>{children}</Suspense>;
}

LoadingSuspense.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

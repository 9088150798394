import { useQuery } from "@tanstack/react-query";
import getUserData from "api/userData/getUserData";
import LOCAL_STORAGE_KEYS from "constants/localStorageKeys";
import useLocalStorage from "context/localStorageContext/useLocalStorage";
import { userTokenKey } from "queryKeys/userKey";

const STALE_TIME = 60 * 1_000; // 1 min

export default function useGetUserDataQuery(options) {
  const storage = useLocalStorage();
  const token = storage.getItem(LOCAL_STORAGE_KEYS.token);

  return useQuery({
    queryKey: userTokenKey(token),
    queryFn: getUserData,
    placeholderData: {},
    staleTime: STALE_TIME,
    keepPreviousData: true,
    enabled: !!token,
    ...options,
  });
}

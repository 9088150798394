import primaryButtonCss from "assets/css/buttons/primaryButton.css";
import { css } from "@emotion/react";

// eslint-disable-next-line import/prefer-default-export
export const loginBtnCss = [
  primaryButtonCss,
  css`
    padding: 12px 36.5px;
    font-size: unset;
  `,
];

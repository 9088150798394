/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { ReactComponent as MarvelousLogo } from "assets/icons/Logo.svg";
import { drawerWrapperCss, logoContainerCss } from "./index.css";

export default function Sidebar({ children }) {
  return (
    <div className="flex">
      <div css={drawerWrapperCss}>
        <div css={logoContainerCss}>
          <MarvelousLogo />
        </div>

        {children}
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { NavLink, Link } from "react-router-dom";
import { dividerCss } from "assets/css/divider.css";
import { menuItemCss, menuItemRedCss } from "assets/css/linkCss";
import { ReactComponent as LogOutIcon } from "assets/icons/LogOut.svg";
import { ReactComponent as UserIcon } from "assets/icons/User.svg";
import ShowContentForRoles from "components/ShowContentForRoles";
import LOCAL_STORAGE_KEYS from "constants/localStorageKeys";
import ROLES from "constants/user";
import ROUTE_PATHS from "constants/routePaths";
import useLocalStorage from "context/localStorageContext/useLocalStorage";
import useIsProspectingRole from "hooks/useIsProspectingRole";
import BugReport from "./BugReport";
import LogAsAdminButton from "./LogAsAdminButton";
import MenuDropdown from "../MenuDropdown";

const OBSERVER_ROLES = [ROLES.OBSERVER, ROLES.OBSERVER_SELLER];
const SHOW_ROLES = [ROLES.EXTERNAL, ROLES.CLIENT_ADMIN, ROLES.SUPER_ADMIN, ROLES.PROSPECTING];

export default function AccountInfoMenu({ isOpen }) {
  const { getItem, removeItem } = useLocalStorage();

  const isProspectingRole = useIsProspectingRole();

  const handleLogout = () => {
    removeItem(LOCAL_STORAGE_KEYS.token);
    window.sessionStorage.clear();
  };

  const adminToken = getItem(LOCAL_STORAGE_KEYS.adminToken);

  return (
    <MenuDropdown isOpen={isOpen}>
      <ShowContentForRoles roles={SHOW_ROLES}>
        {!isProspectingRole && (
          <NavLink to={ROUTE_PATHS.profile} css={menuItemCss}>
            <UserIcon />

            <span>my account</span>
          </NavLink>
        )}

        <BugReport />

        <div css={dividerCss} />
      </ShowContentForRoles>

      <ShowContentForRoles roles={OBSERVER_ROLES}>
        <NavLink css={menuItemCss} to={ROUTE_PATHS.changePassword}>
          <UserIcon />

          <span>Change password</span>
        </NavLink>
      </ShowContentForRoles>

      {adminToken && <LogAsAdminButton adminToken={adminToken} />}

      <Link to={ROUTE_PATHS.root} css={menuItemRedCss} onClick={handleLogout}>
        <LogOutIcon />

        <span>log out</span>
      </Link>
    </MenuDropdown>
  );
}

AccountInfoMenu.propTypes = {
  isOpen: PropTypes.bool,
};

import { ReactComponent as DashboardIcon } from "assets/icons/Dashboard.svg";
import { ReactComponent as MediaIcon } from "assets/icons/Media.svg";
import { ReactComponent as ProspectsIcon } from "assets/icons/Prospects.svg";
import { ReactComponent as TemplatesIcon } from "assets/icons/Templates.svg";
import { ReactComponent as UserManagementIcon } from "assets/icons/UserManagementIcon.svg";
import ROLES from "constants/user";
import ROUTE_PATHS from "constants/routePaths";

const SUPER_ADMIN_ROLES = [ROLES.SUPER_ADMIN];
const ALL_ROLES = [
  ...SUPER_ADMIN_ROLES,
  ROLES.CLIENT_ADMIN,
  ROLES.EXTERNAL,
  ROLES.OBSERVER,
  ROLES.OBSERVER_SELLER,
];

const PROSPECTING_ROLES = [...ALL_ROLES, ROLES.PROSPECTING];

export default [
  {
    name: "dashboard",
    icon: <DashboardIcon />,
    to: ROUTE_PATHS.admin,
    end: true,
    roles: PROSPECTING_ROLES,
  },
  {
    name: "media",
    icon: <MediaIcon />,
    to: ROUTE_PATHS.media,
    roles: ALL_ROLES,
  },
  {
    name: "prospects",
    icon: <ProspectsIcon />,
    to: ROUTE_PATHS.prospects,
    roles: [ROLES.SUPER_ADMIN, ROLES.PROSPECTING],
  },
  {
    name: "sellers",
    icon: <UserManagementIcon />,
    to: ROUTE_PATHS.sellers,
    roles: SUPER_ADMIN_ROLES,
  },
  {
    name: "templates",
    icon: <TemplatesIcon />,
    to: ROUTE_PATHS.templates,
    roles: ALL_ROLES,
  },
];

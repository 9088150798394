/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { NavLink as RNavLink } from "react-router-dom";
import { linkCss } from "assets/css/linkCss";

export default function NavLink({ to, end, disabled, children }) {
  if (disabled) {
    return (
      <span css={linkCss} disabled>
        {children}
      </span>
    );
  }

  return (
    <RNavLink to={to} end={end} css={linkCss}>
      {children}
    </RNavLink>
  );
}

NavLink.propTypes = {
  disabled: PropTypes.bool,
  to: PropTypes.string,
  end: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

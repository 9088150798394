import { css } from "@emotion/react";

export default css`
  .flex,
  .flex-a-c,
  .flex-a-c-j-c,
  .flex-a-c-j-e,
  .flex-a-c-j-sb,
  .flex-a-c-j-se,
  .flex-a-c-j-st,
  .flex-a-e,
  .flex-a-st,
  .flex-a-st-j-c,
  .flex-a-st-j-sb,
  .flex-a-st-j-st,
  .flex-column,
  .flex-column-a-c,
  .flex-column-a-c-j-c,
  .flex-column-a-c-j-sb,
  .flex-column-a-st,
  .flex-column-j-c,
  .flex-j-c,
  .flex-j-e,
  .flex-j-st,
  .flex-j-sb {
    display: flex;
  }

  .inline-flex {
    display: inline-flex;
  }

  .flex-column,
  .flex-column-a-c,
  .flex-column-a-c-j-c,
  .flex-column-a-c-j-sb,
  .flex-column-a-st,
  .flex-column-j-c {
    flex-direction: column;
  }

  .flex-a-c,
  .flex-a-c-j-c,
  .flex-a-c-j-e,
  .flex-a-c-j-sb,
  .flex-a-c-j-se,
  .flex-a-c-j-st,
  .flex-column-a-c,
  .flex-column-a-c-j-c,
  .flex-column-a-c-j-sb {
    align-items: center;
  }

  .flex-a-st,
  .flex-a-st-j-c,
  .flex-a-st-j-sb,
  .flex-a-st-j-st,
  .flex-column-a-st {
    align-items: start;
  }

  .flex-a-e {
    align-items: end;
  }

  .flex-a-c-j-c,
  .flex-a-st-j-c,
  .flex-column-a-c-j-c,
  .flex-column-j-c,
  .flex-j-c {
    justify-content: center;
  }

  .flex-a-st-j-st,
  .flex-j-st {
    justify-content: start;
  }

  .flex-a-c-j-sb,
  .flex-a-st-j-sb,
  .flex-column-a-c-j-sb,
  .flex-j-sb {
    justify-content: space-between;
  }

  .flex-a-c-j-se {
    justify-content: space-evenly;
  }

  .flex-a-c-j-st {
    justify-content: flex-start;
  }

  .flex-j-e,
  .flex-a-c-j-e {
    justify-content: flex-end;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .align-self-stretch {
    align-self: stretch;
  }
`;
